.main .ant-row {
  margin: 0 10px;
}
.main {
  padding-bottom: 50px;
}
.main-menu {
  /* background: #fff; */
  -webkit-box-shadow: 0 2px 8px #f0f1f2;
  box-shadow: 0 2px 8px #f0f1f2;
  position: relative;
  z-index: 10;
  max-width: 100%;
  min-height: 5rem;
}

.header-logo {
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.download-button {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
