body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  min-height: 100%;
  margin: 0;
}

.main .ant-row {
  margin: 0 10px;
}
.main {
  padding-bottom: 50px;
}
.main-menu {
  /* background: #fff; */
  box-shadow: 0 2px 8px #f0f1f2;
  position: relative;
  z-index: 10;
  max-width: 100%;
  min-height: 5rem;
}

.header-logo {
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.download-button {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.login-form {
  width: 100%;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-logo {
  width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

