.login-form {
  width: 100%;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-logo {
  width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
